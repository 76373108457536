import { useRouteError } from 'react-router-dom';

const ErrorBoundary = () => {
  const err = useRouteError() as { message: string };

  return (
    <div title="发生异常" />
  );
};

export default ErrorBoundary;
