import { useTranslation } from 'react-i18next';

import styles from './style.less';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <h1 className={styles['footer-name']}>{`${t('name')}`}</h1>
      <div className={styles.copyright}>
        © 2024 feidian.com ALL Rights Reserved
      </div>
    </div>
  )
}

export default Footer;
