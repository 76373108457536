import { FloatButton } from 'antd';
import Footer from "./Footer";
import NavBar from "./Nav";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { children } = props;



  return (
    <>
      {/* <Helmet>
        <title>{title}</title>
      </Helmet> */}

      <NavBar />

      {children}

      <Footer />

      <FloatButton.BackTop />
    </>
  )
}

export default Layout;
