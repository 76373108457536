import React, { FC } from "react";

import styles from './style.less';

interface WrapProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const Wrap: FC<WrapProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <div className={styles['main-width']} {...rest}>
      {children}
    </div>
  )
}
export default Wrap;
