import { useState } from 'react';
import { Button, Anchor } from 'antd';
import { useTranslation } from 'react-i18next';


import styles from './style.less';
import Wrap from '@/components/Wrap';

const zh = 'zh-CN';

const Nav = () => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(sessionStorage.getItem('language') || navigator.language)


  const changeLanguage = () => {
    const key = language === zh ? 'en' : zh;
    setLanguage(key);
    sessionStorage.setItem('language', key);

    i18n.changeLanguage(key)
  }

  const list = [
    {
      key: 'banner',
      href: '#banner',
      title: t('nav.home'),
    },
    {
      key: 'actions',
      href: '#actions',
      title: t('nav.tech_actions'),
    },
    {
      key: 'adv',
      href: '#adv',
      title: t('nav.tech_adv'),
    },
    {
      key: 'contract',
      href: '#contract',
      title: t('nav.contract'),
    },
  ]

  return (
    <header className={styles['nav-container']}>
      <Wrap>
        <div className={styles['nav-header']}>
          <div className={styles['nav-logo']}>
            {`${t('name')}`}
          </div>
          <div className={styles['nav-anchor']}>
            <Anchor
              bounds={10}
              affix={false}
              direction="horizontal"
              offsetTop={78}
              items={list}
            />
          </div>

          <div className={styles['nav-actions']}>
            <Button ghost type="primary" size="small" onClick={changeLanguage}>{language === zh ? 'English' : '中文'}</Button>
          </div>
        </div>
      </Wrap>
    </header>
  );
}
export default Nav;
