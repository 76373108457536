import styles from './style.less';

const PageLoading = () => (
    <div className={styles['page-loading']}>
      <div />
      <div />
    </div>
  )

export default PageLoading;
